<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="8" md="6">
            <v-row v-if="usaTemaMineduc">
              <v-col class="py-0">
                <img src="./assets/logo_subesup.png" style="max-width: 150px;">
              </v-col>
            </v-row>
            <v-row class="pt-5">
              <v-col class="d-flex justify-center">
                <a v-if="usaTemaMineduc" target="_blank" rel="noopener noreferrer" href="https://acceso.mineduc.cl/portal-inscripcion/">
                  <v-img
                    max-width="330px"
                    contain
                    src="./assets/logo_acceso.png"
                  />
                </a>
                <v-img
                  v-else
                  contain
                  max-width="330px"
                  :class="{ 'px-4' : $vuetify.breakpoint.width < 330 }"
                  :src="$archivos('/public/logo_demre_400.png')"
                />
              </v-col>
            </v-row>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <component :is="footer" />
  </v-app>
</template>

<script>
import DFooter from '@demre/client-common/src/components/footer';
const MFooter = () => import('././components/FooterMineduc');

export default {
  computed: {
    footer() {
      return this.usaTemaMineduc &&  MFooter || DFooter;
    },
    usaTemaMineduc() {
      return window.DATA && window.DATA.usaTemaMineduc == 'S';
    }
  }
};
</script>

<style>
  .v-application .link {
    margin-left: 5px;
    color: #777;
    text-decoration: none;
  }

  .v-application .link:hover {
    color: rgb(25, 118, 210);
  }
</style>
